import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Packages
import { Alert, Button, Checkbox, Col, Form, InputNumber, Popconfirm, Row, Slider, Space, Typography } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Actions
import { rule_create, rule_delete, rule_update } from '../../../redux/actions/rulesActions';

// Utils
import isEmpty from '../../../utils/isEmpty';

const RuleForm = (props) => {
	const { messageApi, stepInfo, update, ruleToUpdate } = props;

	const { t } = useTranslation();

	const dispatch = useDispatch();

	const { step_game_modal_open } = useSelector(state => state.steps);

	const { rule_create_loading, rule_update_loading, rule_delete_loading, rules_errors } = useSelector(state => state.rules);

	const [showInputs, setShowInputs] = useState(false);
	const [offsetSliderValue, setOffsetSliderValue] = useState(0);

	// const formatter = (value) => `${Math.trunc(value * 100)}%`;

	const [form] = Form.useForm();

	useEffect(() => {
		if (step_game_modal_open === false) {
			form.resetFields();
		}
	}, [step_game_modal_open]);

	useEffect(() => {
		if (!isEmpty(ruleToUpdate)) {
			form.setFieldsValue({
				score: ruleToUpdate.score,
				optimal_value: ruleToUpdate.optimal !== null && ruleToUpdate.offset !== null,
				optimal: ruleToUpdate.optimal,
				offset: ruleToUpdate.offset
			});

			setOffsetSliderValue(ruleToUpdate.offset || 0);

			setShowInputs(ruleToUpdate.optimal !== null && ruleToUpdate.offset !== null);
		}
	}, [ruleToUpdate]);

	const onValuesChange = (changedValues, allValues) => {
		if ('optimal_value' in changedValues) {
			if (changedValues.optimal_value === false) {
				form.setFieldsValue({
					optimal: null,
					offset: null
				});
			}
			
			setShowInputs(changedValues.optimal_value);
		}
	}

	const onOk = () => {
		form
			.validateFields()
			.then((values) => {
				delete values.optimal_value;
				const ruleBody = {
					score: values.score,
					...(showInputs ? { optimal: values.optimal } : { optimal: null }),
					...(showInputs
						?	{ offset: values.offset !== undefined ? values.offset : 0 }
						:	{ offset: null }
					),
					recipe: stepInfo.recipe.$oid,
					step: stepInfo._id.$oid
				};
				if (!update) {
					// Create
					dispatch(rule_create(messageApi, ruleBody, stepInfo));
				}
				else {
					// Update
					dispatch(rule_update(messageApi, ruleToUpdate._id.$oid, ruleBody));
				}
			})
			.catch((info) => {
				console.error(info);
			});
	}

	return (
		<Form
			labelCol={{ span: 5 }}
			form={form}
			onFinish={onOk}
			onValuesChange={onValuesChange}
		>
			{rules_errors.hasOwnProperty('rule_create') &&
				<Alert
					style={{ textAlign: 'start', marginBottom: '1.5rem' }}
					message={t('rules.create.errorAlert')}
					type='error'
					showIcon
				/>
			}
			{rules_errors.hasOwnProperty('rule_update') &&
				<Alert
					style={{ textAlign: 'start', marginBottom: '1.5rem' }}
					message={t('rules.edit.errorAlert')}
					type='error'
					showIcon
				/>
			}
			<Form.Item
				label={t('form.label.points')}
				name='score'
				rules={[
					{
						required: true,
						message: t('form.rules.required'),
					}
				]}
			>
				<InputNumber />
			</Form.Item>
			<Form.Item
				name='optimal_value'
				valuePropName='checked'
				wrapperCol={{
					offset: 5,
					span: 16
				}}
			>
				<Checkbox>{t('form.label.optimalValue')}</Checkbox>
			</Form.Item>
			{showInputs &&
				<>
					<Form.Item
						label={t('form.label.optimalValue')}
						name='optimal'
					>
						<InputNumber />
					</Form.Item>
					<Form.Item
						label={t('form.label.offset')}
						name='offset'
					>
						<Row align={'middle'} justify={'space-between'} style={{ width: '100%' }}>
							<Col span={21}>
								<Slider
									min={0}
									max={1}
									step={0.01}
									tooltip={{ formatter: null }}
									value={offsetSliderValue}
									onChange={(value) => {
										setOffsetSliderValue(value);
										form.setFieldsValue({ offset: value });
									}}
								/>
							</Col>
							<Col span={2} style={{ textAlign: 'center' }}>
								<Typography.Text type='secondary'>{parseInt(offsetSliderValue * 100)}%</Typography.Text>
							</Col>
						</Row>
					</Form.Item>
				</>
			}
			<Form.Item
				wrapperCol={{
					offset: 5
				}}
			>
				<Space>
					<Button
						type='primary'
						htmlType='submit'
						loading={!update ? rule_create_loading : rule_update_loading}
					>
						{t('buttons.save')}
					</Button>
					{!isEmpty(ruleToUpdate) &&
						<Popconfirm
							title={t('rules.delete.confirmTitle')}
							description={t('rules.delete.confirmText')}
							icon={<QuestionCircleFilled style={{ color: '#1677FF' }} />}
							onConfirm={() => dispatch(rule_delete(messageApi, ruleToUpdate._id.$oid, form, stepInfo))}
							okButtonProps={{
								loading: rule_delete_loading
							}}
							okText={t('buttons.confirm')}
							cancelText={t('buttons.cancel')}
						>
							<Button
								htmlType='button'
								onClick={() => {}}
							>
								{t('buttons.delete')}
							</Button>
						</Popconfirm>
					}
				</Space>
			</Form.Item>
		</Form>
	)
}

RuleForm.propTypes = {
	messageApi: PropTypes.object.isRequired,
	stepInfo: PropTypes.object.isRequired,
	update: PropTypes.bool.isRequired,
	ruleToUpdate: PropTypes.object
}

export default RuleForm;
